import { Link, Text } from '@pancakeswap/uikit'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
import { useTranslation } from '@pancakeswap/localization'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useActiveChainId } from '../../hooks/useActiveChainId'
import React from 'react'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
  txChainId?: number
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({
  txHash,
  txChainId,
  children,
}) => {
  const { chainId } = useActiveChainId()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? (
        <Text fontSize={['12px', null, null, '16px']} as="p">
          {children}
        </Text>
      ) : (
        children
      )}
      {txHash && (
        <Link
          external
          fontSize={['13px', null, null, '16px']}
          target="_blank"
          href={getBlockExploreLink(txHash, 'transaction', txChainId || chainId)}
        >
          {t('View on %site%', { site: getBlockExploreName(txChainId || chainId) })}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
