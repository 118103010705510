import { ChainId } from '@pancakeswap/sdk'
import { isStableFarm, SerializedFarmConfig } from '@pancakeswap/farms'

let farmingDataCache: SerializedFarmConfig[]

export const getFarmConfig = async (chainId: ChainId) => {
  const farmConfigApi = process.env.NEXT_PUBLIC_STAKING_API_URL
  let logged = false

  const fetchFarmData = async (url: string, options: RequestInit = {}) => {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}`)
    }
    return response.json()
  }

  try {
    const farmList = await fetchFarmData(`${farmConfigApi}/farms`)
    const farmSuccess = farmList
    const modifiedFarmList = farmSuccess.map((farm, index) => ({
      pid: index,
      lpSymbol: `${farm.lpSymbol?.replace(' - ', '-') || ''} LP`,
      lpAddress: farm.lpAddress || '',
      token: farm.token
        ? {
            address: farm.token.address || '',
            chainId: farm.token.chainId || 97,
            decimals: Number(farm.token.decimals) || '',
            symbol: farm.token.symbol || '',
            name: (farm.token.name || '').replace(' Token', ''),
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      quoteToken: farm.quoteToken
        ? {
            address: farm.quoteToken.address || '',
            chainId: farm.quoteToken.chainId || 97,
            decimals: Number(farm.quoteToken.decimals) || '',
            symbol: farm.quoteToken.symbol || '',
            name: farm.quoteToken.name || '',
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      owner: farm.owner,
      status: farm.status,
      createdAt: farm.createdAt,
    }))
    const dataReturn = modifiedFarmList.filter((f: SerializedFarmConfig) => f.pid !== null) as SerializedFarmConfig[]
    farmingDataCache = dataReturn
    return dataReturn
  } catch (error) {
    if (!logged) {
      console.error('Cannot get farm config', error, chainId)
      logged = true
    }
    return farmingDataCache
  }
  // const currentPage = window.location.pathname
  // const farms = currentPage === '/farms' ? data : mydata
  // try {
  //   return farms.filter((f: SerializedFarmConfig) => f.pid !== null) as SerializedFarmConfig[]
  // } catch (error) {
  //   if (!logged) {
  //     console.error('Cannot get farm config', error, chainId)
  //     logged = true
  //   }
  //   return []
  // }
}

// export const getFarmConfig = async (chainId: ChainId) => {
//   try {
//     return (await import(`/${chainId}.ts`)).default.filter(
//       (f: SerializedFarmConfig) => f.pid !== null,
//     ) as SerializedFarmConfig[]
//   } catch (error) {
//     if (!logged) {
//       console.error('Cannot get farm config', error, chainId)
//       logged = true
//     }
//     return []
//   }
// }

export const getStableConfig = async (chainId: ChainId) => {
  const farmConfigApi = process.env.NEXT_PUBLIC_STAKING_API_URL
  let logged = false

  const fetchFarmData = async (url: string, options: RequestInit = {}) => {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}`)
    }
    return response.json()
  }

  try {
    const farmList = await fetchFarmData(`${farmConfigApi}/farms`)

    const farmSuccess = farmList
    const modifiedFarmList = farmSuccess.map((farm, index) => ({
      pid: index,
      lpSymbol: `${farm.lpSymbol?.replace(' - ', '-') || ''} LP`,
      lpAddress: farm.lpAddress || '',
      token: farm.token
        ? {
            address: farm.token.address || '',
            chainId: farm.token.chainId || 97,
            decimals: Number(farm.token.decimals) || '',
            symbol: farm.token.symbol || '',
            name: (farm.token.name || '').replace(' Token', ''),
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      quoteToken: farm.quoteToken
        ? {
            address: farm.quoteToken.address || '',
            chainId: farm.quoteToken.chainId || 97,
            decimals: Number(farm.quoteToken.decimals) || '',
            symbol: farm.quoteToken.symbol || '',
            name: farm.quoteToken.name || '',
            projectLink: '',
          }
        : {
            address: '',
            chainId: '',
            decimals: '',
            symbol: '',
            name: '',
            projectLink: '',
          },
      owner: farm.owner,
      status: farm.status,
    }))
    const dataReturn = modifiedFarmList.filter(isStableFarm)
    farmingDataCache = dataReturn
    return dataReturn
  } catch (error) {
    if (!logged) {
      console.error('Cannot get farm config', error, chainId)
      logged = true
    }
    return farmingDataCache
  }
  // try {
  //   const farms = farm as SerializedFarmConfig[]

  //   return farms.filter(isStableFarm)
  // } catch (error) {
  //   if (!logged) {
  //     console.error('Cannot get stable farm config', error, chainId)
  //     logged = true
  //   }
  //   return []
  // }
}

// export const getStableConfig = async (chainId: ChainId) => {
//   try {
//     const farms = (await import(`/${chainId}.ts`)).default as SerializedFarmConfig[]

//     return farms.filter(isStableFarm)
//   } catch (error) {
//     if (!logged) {
//       console.error('Cannot get stable farm config', error, chainId)
//       logged = true
//     }
//     return []
//   }
// }
