import React, { SVGProps } from 'react'

export const TradeIcon = ({ props }: { props: SVGProps<any> }) => {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.12 9a6.5 6.5 0 115.98 5.981M22.1 17a3 3 0 01-3 3h-2m0 0l2-2m-2 2l2 2M2.1 7a3 3 0 013-3h2m0 0l-2 2m2-2l-2-2m3.5 20a6.5 6.5 0 110-13 6.5 6.5 0 010 13z"
        stroke="#596780"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
