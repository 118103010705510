import React, { SVGProps } from 'react'

export const DropdownIcon = ({ props }: { props?: SVGProps<any> }) => {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.42144 11.9163L5.25477 9.74967C4.99088 9.48579 4.93199 9.18384 5.0781 8.84384C5.22422 8.50384 5.48449 8.33356 5.85894 8.33301H10.1506C10.5256 8.33301 10.7862 8.50329 10.9323 8.84384C11.0784 9.1844 11.0192 9.48634 10.7548 9.74967L8.5881 11.9163C8.50477 11.9997 8.41449 12.0622 8.31727 12.1038C8.22005 12.1455 8.11588 12.1663 8.00477 12.1663C7.89366 12.1663 7.78949 12.1455 7.69227 12.1038C7.59505 12.0622 7.50477 11.9997 7.42144 11.9163Z"
        fill="#A3AED0"
      />
    </svg>
  )
}
