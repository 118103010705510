import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import {
  PoolsState,
  SerializedPool,
  SerializedVaultFees,
  SerializedCakeVault,
  SerializedLockedVaultUser,
  PublicIfoData,
  SerializedVaultUser,
  SerializedLockedCakeVault,
} from '../types'
import { getPoolApr } from '../../utils/apr'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import cakeAbi from '../../config/abi/cake.json'
import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from '../../utils/addressHelpers'
import multicall, { multicallv2 } from '../../utils/multicall'
import { bsctestTokens, USD } from '@pancakeswap/tokens'
import { isAddress } from '../../utils'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { getPoolsPriceHelperLpFiles } from '../../config/constants/priceHelperLps/index'
import fetchFarms from '../farms/fetchFarms'
import { getFarmsPrices } from '@pancakeswap/farms/farmPrices'
import {
  fetchPoolsBlockLimits,
  fetchPoolsProfileRequirement,
  fetchPoolsStakingLimits,
  fetchPoolsTotalStaking,
} from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees, fetchPublicFlexibleSideVaultData } from './fetchVaultPublic'
import { getTokenPricesFromFarm } from './helpers'
import { resetUserState } from '../global/actions'
import { fetchUserIfoCredit, fetchPublicIfoData } from './fetchUserIfo'
import { fetchVaultUser, fetchFlexibleSideVaultUser } from './fetchVaultUser'
import { ChainId, WETH9 } from '@pancakeswap/sdk'
import axios from 'axios'
import { ERC20Token } from '@pancakeswap/sdk'
// import data from '../../config/constants/staking.json'
import erc20ABI from '../../config/abi/erc20.json'
import { getChain } from '@icecreamswap/constants'
import { providers } from 'ethers'
import { fetchStakingData } from 'utils/calls/pools'
import store from 'state'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

export const initialIfoState = Object.freeze({
  credit: null,
  ceiling: null,
})

const url = (typeof window !== 'undefined' && window.location.href) || ''
let parsedUrl
let path = ''

try {
  parsedUrl = new URL(url)
  path = parsedUrl.pathname
} catch (error) {
  console.error('Error parsing URL:', error)
}

const initialState: PoolsState = {
  data: [],
  userDataLoaded: false,
  cakeVault: initialPoolVaultState,
  ifo: initialIfoState,
  cakeFlexibleSideVault: initialPoolVaultState,
  myStakeData: [],
  myStakeList: [],
  myStakePending: [],
}

const cakeVaultAddress = getCakeVaultAddress()

export const fetchCakePoolPublicDataAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const data = await fetchStakingData()
  const livePools = data
  const finishedPools = [].map((p) => ({
    ...p,
    isFinished: true,
  }))
  const poolsConfig = [...livePools, ...finishedPools]
  const farmsData = getState().farms.data
  const prices = getTokenPricesFromFarm(farmsData)

  const cakePool = poolsConfig
    .filter((poolConfig) => chainId in poolConfig.contractAddress)
    .filter((p) => p.sousId === 0)[0]

  const stakingTokenAddress = isAddress(cakePool.stakingToken.address)
  const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

  const earningTokenAddress = isAddress(cakePool.earningToken.address)
  const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

  dispatch(
    setPoolPublicData({
      sousId: 0,
      data: {
        stakingTokenPrice,
        earningTokenPrice,
      },
    }),
  )
}

export const fetchCakePoolUserDataAsync = (account: string, chainId: ChainId) => async (dispatch) => {
  const allowanceCall = {
    address: bsctestTokens.cra.address,
    name: 'allowance',
    params: [account, cakeVaultAddress],
  }
  const balanceOfCall = {
    address: bsctestTokens.cra.address,
    name: 'balanceOf',
    params: [account],
  }
  const cakeContractCalls = [allowanceCall, balanceOfCall]
  // @ts-ignore fix chainId support
  const [[allowance], [stakingTokenBalance]] = await multicallv2({ abi: cakeAbi, calls: cakeContractCalls, chainId })

  dispatch(
    setPoolUserData({
      sousId: 0,
      data: {
        allowance: new BigNumber(allowance.toString()).toJSON(),
        stakingTokenBalance: new BigNumber(stakingTokenBalance.toString()).toJSON(),
      },
    }),
  )
}

export const fetchPoolsPublicDataAsync =
  (currentBlockNumber: number, chainId: number) => async (dispatch, getState) => {
    const chain = getChain(Number(chainId))
    const provider = new providers.JsonRpcProvider(chain.rpcUrls.default)

    const data = await fetchStakingData()
    const livePools = data
    const finishedPools = [].map((p) => ({
      ...p,
      isFinished: true,
    }))
    const poolsConfig = [...livePools, ...finishedPools]
    try {
      const [blockLimits, totalStakings, profileRequirements] = await Promise.all([
        fetchPoolsBlockLimits(chainId),
        fetchPoolsTotalStaking(chainId),
        fetchPoolsProfileRequirement(chainId),
      ])
      const currentBlock = currentBlockNumber > 0 ? currentBlockNumber : await provider.getBlockNumber() // todo: currentBlockNumber can be 0, query correct block number if it is
      // bscRpcProvider.getBlockNumber() could be one way, but the providers seem to not be directly available for all chains

      const blockLimitsSousIdMap = fromPairs(blockLimits.map((entry) => [entry.sousId, entry]))
      const totalStakingsSousIdMap = fromPairs(totalStakings.map((entry) => [entry.sousId, entry]))

      const priceHelperLpsConfig = getPoolsPriceHelperLpFiles(chainId)
      const activePriceHelperLpsConfig = priceHelperLpsConfig.filter((priceHelperLpConfig) => {
        return (
          poolsConfig
            .filter((poolConfig) => chainId in poolConfig.contractAddress)
            .filter(
              (pool) => pool?.earningToken?.address.toLowerCase() === priceHelperLpConfig?.token?.address.toLowerCase(),
            )
            .filter((pool) => {
              const poolBlockLimit = blockLimitsSousIdMap[pool.sousId]
              if (poolBlockLimit) {
                return poolBlockLimit.endBlock > currentBlock
              }
              return false
            }).length > 0
        )
      })

      const poolsWithDifferentFarmToken =
        activePriceHelperLpsConfig.length > 0 ? await fetchFarms(priceHelperLpsConfig, chainId) : []
      const farmsData = getState().farms.data
      const bnbBusdFarm =
        activePriceHelperLpsConfig.length > 0
          ? farmsData.find(
              (farm) =>
                farm.token?.address === USD[chainId]?.address && farm.quoteToken?.address === WETH9[chainId]?.address,
            )
          : null
      const farmsWithPricesOfDifferentTokenPools =
        bnbBusdFarm && 'tokenPriceVsQuote' in bnbBusdFarm
          ? getFarmsPrices([bnbBusdFarm, ...poolsWithDifferentFarmToken], {
              address: bnbBusdFarm.lpAddress,
              wNative: bnbBusdFarm.quoteToken.symbol,
              stable: bnbBusdFarm.token.symbol,
            })
          : []
      const prices = getTokenPricesFromFarm([...farmsData, ...farmsWithPricesOfDifferentTokenPools])

      const liveData = poolsConfig
        .filter((poolConfig) => chainId in poolConfig.contractAddress)
        .map((pool) => {
          const blockLimit = blockLimitsSousIdMap[pool.sousId]
          const totalStaking = totalStakingsSousIdMap[pool.sousId]
          const isPoolEndBlockExceeded =
            currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
          const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

          const stakingTokenAddress = isAddress(pool.stakingToken?.address)
          const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

          const earningTokenAddress = isAddress(pool.earningToken?.address)
          const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
          const apr = !isPoolFinished
            ? (pool.fixedApr && parseFloat(pool.fixedApr)) ||
              getPoolApr(
                stakingTokenPrice,
                earningTokenPrice,
                getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken?.decimals),
                parseFloat(pool.tokenPerBlock),
                chainId,
              )
            : 0

          const profileRequirement = profileRequirements[pool.sousId] ? profileRequirements[pool.sousId] : undefined

          return {
            ...blockLimit,
            ...totalStaking,
            profileRequirement,
            stakingTokenPrice,
            earningTokenPrice,
            apr,
            isFinished: isPoolFinished,
          }
        })
      dispatch(setPoolsPublicData(liveData))
    } catch (error) {
      console.error('[Pools Action] error when getting public data', error)
    }
  }

export const fetchPoolsStakingLimitsAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const data = await fetchStakingData()
  const livePools = data
  const finishedPools = [].map((p) => ({
    ...p,
    isFinished: true,
  }))
  const poolsConfig = [...livePools, ...finishedPools]
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.sousId)

  try {
    const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit, chainId)

    const stakingLimitData = poolsConfig
      .filter((poolConfig) => chainId in poolConfig.contractAddress)
      .map((pool) => {
        if (poolsWithStakingLimit.includes(pool.sousId)) {
          return { sousId: pool.sousId }
        }
        const { stakingLimit, numberBlocksForUserLimit } = stakingLimits[pool.sousId] || {
          stakingLimit: BIG_ZERO,
          numberBlocksForUserLimit: 0,
        }
        return {
          sousId: pool.sousId,
          stakingLimit: stakingLimit.toJSON(),
          numberBlocksForUserLimit,
        }
      })

    dispatch(setPoolsPublicData(stakingLimitData))
  } catch (error) {
    console.error('[Pools Action] error when getting staking limits', error)
  }
}

export const fetchPoolsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[],
  { account: string; chainId: ChainId }
>('pool/fetchPoolsUserData', async ({ account, chainId }, { rejectWithValue }) => {
  try {
    const [allowances, stakingTokenBalances, stakedBalances, pendingRewards] = await Promise.all([
      fetchPoolsAllowance(account, chainId),
      fetchUserBalances(account, chainId),
      fetchUserStakeBalances(account, chainId),
      fetchUserPendingRewards(account, chainId),
    ])

    const data = await fetchStakingData()
    const livePools = data
    const finishedPools = [].map((p) => ({
      ...p,
      isFinished: true,
    }))
    const poolsConfig = [...livePools, ...finishedPools]
    const userData = poolsConfig
      .filter((poolConfig) => chainId in poolConfig.contractAddress)
      .map((pool) => ({
        sousId: pool.sousId,
        allowance: allowances[pool.sousId],
        stakingTokenBalance: stakingTokenBalances[pool.sousId],
        stakedBalance: stakedBalances[pool.sousId],
        pendingReward: pendingRewards[pool.sousId],
      }))
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const updateUserAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserAllowance', async ({ sousId, account, chainId }) => {
  const allowances = await fetchPoolsAllowance(account, chainId)
  return { sousId, field: 'allowance', value: allowances[sousId] }
})

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserBalance', async ({ sousId, account, chainId }) => {
  const tokenBalances = await fetchUserBalances(account, chainId)
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserStakedBalance', async ({ sousId, account, chainId }) => {
  const stakedBalances = await fetchUserStakeBalances(account, chainId)
  return { sousId, field: 'stakedBalance', value: stakedBalances[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserPendingReward', async ({ sousId, account, chainId }) => {
  const pendingRewards = await fetchUserPendingRewards(account, chainId)
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})

export const fetchCakeVaultPublicData = createAsyncThunk<SerializedLockedCakeVault, ChainId>(
  'cakeVault/fetchPublicData',
  async (chainId: ChainId) => {
    const publicVaultInfo = await fetchPublicVaultData(chainId)
    return publicVaultInfo
  },
)

export const fetchCakeFlexibleSideVaultPublicData = createAsyncThunk<SerializedCakeVault, ChainId>(
  'cakeFlexibleSideVault/fetchPublicData',
  async (arg: ChainId) => {
    const publicVaultInfo = await fetchPublicFlexibleSideVaultData(arg)
    return publicVaultInfo
  },
)

export const fetchCakeVaultFees = createAsyncThunk<SerializedVaultFees, ChainId>(
  'cakeVault/fetchFees',
  async (chainId: ChainId) => {
    const vaultFees = await fetchVaultFees(chainId, getCakeVaultAddress())
    return vaultFees
  },
)

export const fetchCakeFlexibleSideVaultFees = createAsyncThunk<SerializedVaultFees, ChainId>(
  'cakeFlexibleSideVault/fetchFees',
  async (chainId: ChainId) => {
    const vaultFees = await fetchVaultFees(chainId, getCakeFlexibleSideVaultAddress())
    return vaultFees
  },
)

export const fetchCakeVaultUserData = createAsyncThunk<
  SerializedLockedVaultUser,
  { account: string; chainId: ChainId }
>('cakeVault/fetchUser', async ({ account, chainId }) => {
  const userData = await fetchVaultUser(account, chainId)
  return userData
})

export const fetchIfoPublicDataAsync = createAsyncThunk<PublicIfoData>('ifoVault/fetchIfoPublicDataAsync', async () => {
  const publicIfoData = await fetchPublicIfoData()
  return publicIfoData
})

export const fetchUserIfoCreditDataAsync = (account: string) => async (dispatch) => {
  try {
    const credit = await fetchUserIfoCredit(account)
    dispatch(setIfoUserCreditData(credit))
  } catch (error) {
    console.error('[Ifo Credit Action] Error fetching user Ifo credit data', error)
  }
}
export const fetchCakeFlexibleSideVaultUserData = createAsyncThunk<SerializedVaultUser, { account: string }>(
  'cakeFlexibleSideVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchFlexibleSideVaultUser(account)
    return userData
  },
)

export const fetchPools = createAsyncThunk('pools/fetchPools', async () => {
  const data = await fetchStakingData()
  return data
})

const getAuthToken = () => {
  return localStorage.getItem('authToken') || ''
}

const checkAddresses = async (addresses: string[], chain_id: number) => {
  const calls = addresses
    .map((address) => ({
      address,
      name: 'name',
      params: [],
    }))
    .concat(
      addresses.map((address) => ({
        address,
        name: 'symbol',
        params: [],
      })),
    )
    .concat(
      addresses.map((address) => ({
        address,
        name: 'decimals',
        params: [],
      })),
    )

  try {
    const results = await multicall(erc20ABI, calls, chain_id)

    const tokens = {}
    addresses.forEach((address, index) => {
      const name = results[index]
      const symbol = results[addresses.length + index]
      const decimals = results[addresses.length * 2 + index]

      tokens[address] = new ERC20Token(chain_id, address, decimals[0], symbol[0], name[0], '')
    })

    return tokens
  } catch (error) {
    return addresses.reduce((acc, address) => {
      acc[address] = {
        address: '',
        chainId: '',
        decimals: '',
        symbol: '',
        name: '',
        projectLink: '',
      }
      return acc
    }, {})
  }
}

export const fetchMyStake = createAsyncThunk('stake/fetchMyStake', async (_, { rejectWithValue }) => {
  try {
    // const response = await axios.get(`http://192.168.0.124:3666/contract`, {
    const response = await axios.get(`https://betacontract.nusabyte.com/contract`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    })

    const params = new URLSearchParams(window.location.search)
    const chainId = params.get('chainId')
    const dataSuccess = response?.data?.filter((item) => item.status == 'success')

    const addresses = [...dataSuccess.map((item) => item.reward_token), ...dataSuccess.map((item) => item.staked_token)]

    const tokens = await checkAddresses(Array.from(new Set(addresses)), Number(chainId))

    const newData = dataSuccess.map((item, index) => {
      const earningToken = tokens[item.reward_token]
      const stakingToken = tokens[item.staked_token]

      return {
        sousId: index + 1,
        poolCategory: 'Core',
        tokenPerBlock: (item.reward_per_block / 10 ** Number(stakingToken.decimals)).toString(),
        version: 2,
        contractAddress: {
          97: item.contract_address,
        },
        earningToken: earningToken,
        stakingToken: stakingToken,
        locking: item.locking,
        status: item.status,
        owner: item.owner,
        createdAt: item.createdAt,
      }
    })

    const livePools = newData.map((p: any) => ({
      ...p,
      stakingToken: p.stakingToken.serialize,
      earningToken: p.earningToken.serialize,
    }))

    const finishedPools = [].map((p) => ({
      ...p,
      isFinished: true,
    }))

    return [...livePools, ...finishedPools]
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error.response?.statusText || error.message)
    }
    return rejectWithValue('Unexpected error')
  }
})

// export const fetchMyStakeData = createAsyncThunk('stake/fetchMyStakeData', async (_, { rejectWithValue }) => {
//   try {
//     // const response = await axios.get(`http://192.168.0.124:3666/contract/me`, {
//     const response = await axios.get(`https://betacontract.nusabyte.com/contract/me`, {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getAuthToken()}`,
//       },
//     })

//     const params = new URLSearchParams(window.location.search)
//     const chainId = params.get('chainId')
//     const dataSuccess = response?.data?.filter((item) => item.status == 'success')

//     const addresses = [...dataSuccess.map((item) => item.reward_token), ...dataSuccess.map((item) => item.staked_token)]

//     const tokens = await checkAddresses(Array.from(new Set(addresses)), Number(chainId))

//     const newData = dataSuccess.map((item, index) => {
//       const earningToken = tokens[item.reward_token]
//       const stakingToken = tokens[item.staked_token]

//       return {
//         sousId: index + 1,
//         poolCategory: 'Core',
//         tokenPerBlock: (item.reward_per_block / 10 ** Number(stakingToken.decimals)).toString(),
//         version: 2,
//         contractAddress: {
//           97: item.contract_address,
//         },
//         earningToken: earningToken,
//         stakingToken: stakingToken,
//         locking: item.locking,
//       }
//     })
//     const livePools = newData.map((p: any) => ({
//       ...p,
//       stakingToken: p.stakingToken.serialize,
//       earningToken: p.earningToken.serialize,
//     }))

//     const finishedPools = [].map((p) => ({
//       ...p,
//       isFinished: true,
//     }))

//     return [...livePools, ...finishedPools]
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       return rejectWithValue(error.response?.statusText || error.message)
//     }
//     return rejectWithValue('Unexpected error')
//   }
// })

export const fetchMyStakePendingData = createAsyncThunk(
  'stake/fetchMyStakePendingData',
  async (_, { rejectWithValue }) => {
    try {
      // const response = await axios.get(`http://192.168.0.124:3666/contract/me`, {
      const response = await axios.get(`https://betacontract.nusabyte.com/contract/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
        },
      })
      const pendingStake = response?.data?.filter((item) => item.status == 'pending')
      return pendingStake
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.statusText || error.message)
      }
      return rejectWithValue('Unexpected error')
    }
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((pool) => {
        if (pool.sousId === sousId) {
          return { ...pool, userDataLoaded: true, userData: action.payload.data }
        }
        return pool
      })
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = fromPairs(livePoolsData.map((entry) => [entry.sousId, entry]))
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsSousIdMap[pool.sousId]
        return { ...pool, ...livePoolData }
      })
    },
    setIfoUserCreditData: (state, action) => {
      const credit = action.payload
      state.ifo = { ...state.ifo, credit }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
      state.cakeVault = { ...state.cakeVault, userData: initialPoolVaultState.userData }
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData: initialPoolVaultState.userData }
    })
    builder.addCase(
      fetchPoolsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = fromPairs(userData.map((entry) => [entry.sousId, entry]))
        state.data = state.data.map((pool) => ({
          ...pool,
          userDataLoaded: true,
          userData: userDataSousIdMap[pool.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchPoolsUserDataAsync.rejected, (state, action) => {
      console.error('[Pools Action] Error fetching pool user data', action.payload)
    })
    builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<SerializedLockedCakeVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultPublicData.fulfilled,
      (state, action: PayloadAction<SerializedCakeVault>) => {
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, ...action.payload }
      },
    )
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    builder.addCase(fetchCakeFlexibleSideVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<SerializedLockedVaultUser>) => {
      const userData = action.payload
      state.cakeVault = { ...state.cakeVault, userData }
    })
    // IFO
    builder.addCase(fetchIfoPublicDataAsync.fulfilled, (state, action: PayloadAction<PublicIfoData>) => {
      const { ceiling } = action.payload
      state.ifo = { ...state.ifo, ceiling }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultUserData.fulfilled,
      (state, action: PayloadAction<SerializedVaultUser>) => {
        const userData = action.payload
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData }
      },
    )
    builder.addCase(fetchMyStake.fulfilled, (state, action) => {
      state.myStakeData = action.payload
    })
    builder.addCase(fetchMyStake.rejected, (state, action) => {
      console.error('[Pools Action] Error fetching pools config', action.payload)
    })
    // builder.addCase(fetchMyStakeData.fulfilled, (state, action) => {
    //   state.myStakeList = action.payload
    // })
    builder.addCase(fetchMyStakePendingData.fulfilled, (state, action) => {
      state.myStakePending = action.payload
    })
    builder.addCase(fetchPools.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
    builder.addMatcher(
      isAnyOf(
        updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserStakedBalance.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setPoolsPublicData, setPoolPublicData, setPoolUserData, setIfoUserCreditData } = PoolsSlice.actions

export default PoolsSlice.reducer
